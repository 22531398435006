<template>
	<div>
		<detail-list :items="items" />
	</div>
</template>

<script>
import DetailList from '../DetailList';

export default {
	name: 'NotificationTabInformation',

	components: {
		DetailList
	},

	props: {
		feature: {
			type: Object,
			required: true
		},
		elasticData: {
			type: Object,
			required: true
		}
	},

	computed: {
		items() {
			const props = ['code', 'coordinates', 'provider' /* 'dateObserved' */];

			const titles = {
				code: this.$t('panels.notification.code'),
				coordinates: this.$t('panels.notification.coordinates'),
				provider: this.$t('panels.notification.provider')
				//dateObserved: this.$t('panels.notification.provider')
			};

			const visible = (value) => value !== null && value !== undefined;

			return props.map((prop) => ({ title: titles[prop], value: this[prop], visible: visible(this[prop]) }));
		},

		code() {
			return this.feature.data['station_code'];
		},

		coordinates() {
			return this.feature.coordinates ? this.feature.coordinates : this.elasticData.location.coordinates;
		},
		provider() {
			return this.feature.data['provider_name'];
		}
	}
};
</script>
