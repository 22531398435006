export default {
	data() {
		return {
			layerName: null,
			dataFeature: {}
		};
	},

	props: {
		options: {
			type: Object,
			required: true
		}
	},

	computed: {
		visible() {
			return this.$store.getters.activePanel === this.options.panelId;
		},

		closeTitleBtn() {
			return this.$t('common.closeTitleBtn');
		}
	},

	methods: {
		closePanel() {
			this.$store.commit('removeDynamicPanelById', this.options.panelId);
		}
	}
};
