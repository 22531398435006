<template>
	<div class="cardparameter my-1 align-start">
		<div class="cardparameter__textparameter">
			<span :style="'color:' + color" class="fs-xl">{{ pollutantValue }}</span
			><span :style="'color:' + color" class="fs-regular">{{ pollutantUnit }}</span>
		</div>
		<line-chart :value="getStatusPercentage()" :color="color" style="width: 100%" />
	</div>
</template>

<script>
import LineChart from '@/components/map/sections/charts/LineChart';
//
export default {
	name: 'NotificationValueCardParameter',

	components: {
		LineChart
	},

	props: {
		pollutantValue: {
			required: true
		},
		pollutantUnit: {
			type: String,
			required: true
		},
		pollutantId: {
			type: Number,
			required: true
		},
		color: {
			type: String,
			required: true
		}
	},
	data() {
		return {};
	},

	computed: {
		percent() {
			return /* this.activeStatus ? this.activeStatus.color : 'gray' */ this.pollutantValue;
			// TODO: parameter status percentage
		}
	},
	mounted() {},
	methods: {
		getStatusPercentage() {
			let statusColors = [];
			//let minStatusValue = 1;
			let maxStatusValue = 100;

			let parameterStatus = this.$store.getters.getParameterStatus.filter((status) => status.pmparameterid === this.pollutantId);
			if (parameterStatus.length > 0) {
				parameterStatus.sort((a, b) => {
					const minimumA = a.minvalue;
					const minimumB = b.minvalue;
					if (minimumA < minimumB) {
						return -1;
					}
					if (minimumA > minimumB) {
						return 1;
					}
					return 0; // Objects have the same minimum (statement should be unreachable)
				});
				//let minStatusValue = parameterStatus[0].minvalue;
				let maxStatusValue = parameterStatus[parameterStatus.length - 1].maxvalue;
				parameterStatus.forEach((status) => {
					statusColors.push([status.maxvalue / maxStatusValue, status.color]);
				});
			}
			console.log('percentage values', maxStatusValue, parseFloat(this.pollutantValue) / maxStatusValue);
			return (parseFloat(this.pollutantValue) / maxStatusValue) * 100;
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../../styles/eiffel-variables.pcss';
.cardparameter {
	& * {
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: normal;
		padding: 5px;
	}
	&__textparameter {
		font-size: 1rem;
		line-height: 1.25;
		color: var(--high);
	}
	& i {
		font-weight: bold;
	}
}
</style>
