<template>
	<v-list color="transparent">
		<div v-if="title" class="detail-list-title px-3">{{ title }}</div>

		<div v-for="(item, index) in visibleItems" :key="index" :class="{ sticky: item.sticky }">
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="labeltitle pb-2">{{ item.title }}</v-list-item-title>
					<slot v-bind="{ item }">
						<v-list-item-subtitle class="d-flex flex-wrap">
							<a v-if="item.href" :href="item.href" class="labelinfo">
								{{ item.value }}
							</a>
							<div class="labelinfo d-flex flex-wrap" v-else-if="Array.isArray(item.value)">
								<span v-for="(value, i) in item.value" :key="i" class="pr-2">{{ value }} </span>
								<!-- <span class="pr-2">{{ item.value[0] }}</span>
								<span>{{ item.value[1] }} </span> -->
							</div>
							<span class="labelinfo d-flex flex-wrap" v-else>{{ item.value }}</span>
						</v-list-item-subtitle>
					</slot>
				</v-list-item-content>
			</v-list-item>

			<v-divider class="separator" />
		</div>
	</v-list>
</template>

<script>
export default {
	props: {
		title: {
			type: String
		},

		items: {
			type: Array,
			required: true
		}
	},

	computed: {
		visibleItems() {
			return this.items.filter(({ visible }) => !!visible);
		}
	},
	mounted() {
		console.log('detail list', this.items, this.visibleItems, Array.isArray(this.items[0].value));
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../styles/eiffel-variables.pcss';

.sticky {
	position: sticky;
	top: 0px;
	z-index: 1;
	background: var(--very-low);
}

.detail-list-title {
	font-size: 1.1rem;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.25;
	letter-spacing: normal;
	color: #000;
}

.labeltitle {
	font-size: 0.9rem;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	color: var(--medium);
}

.labelinfo {
	font-size: 1rem !important;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	color: var(--high);
}

a {
	color: var(--mapfre-accent) !important;
	text-decoration: none;
}

.separator {
	border-color: var(--low) !important;
}
</style>
