<template>
	<v-card flat class="notificationvaluecard my-1">
		<!-- Card Header -->
		<div class="cardheader d-flex align-center justify-space-between pa-1" style="min-width: 30%">
			<div class="d-flex">
				<notification-value-card-status :color="color" class="mx-2"></notification-value-card-status>
				<div class="cardheadertitle mx-2">{{ parameter.name }}</div>
				<!-- <div class="cardheadertitle mx-2">{{ parameter.rulename }}</div> -->
			</div>

			<!-- <v-btn text icon class="cardheaderbtn ml-auto" @click="openPanel" :title="buttonTitle">
				<i class="fas fa-arrow-right"></i>
			</v-btn> -->
			<div class="d-flex">
				<v-tooltip v-if="parameter.isaverage == 1" top transition="none" :color="primaryColor">
					<template v-slot:activator="{ on, attrs }">
						<v-chip :color="primaryColor" class="px-2 mr-1" link pill small v-bind="attrs" v-on="on">
							<span class="fs-xs fw-bold color-white">AVG</span></v-chip
						>
					</template>
					<span class="fs-regular">{{ $t('panels.notifications.average_operator') }}</span>
				</v-tooltip>
				<v-tooltip v-if="statusMessage" right :color="color + 'E6'" transition="scroll-x-transition">
					<template v-slot:activator="{ on, attrs }">
						<div class="mx-2" v-bind="attrs" v-on="on">
							<v-icon size="16">far fa-question-circle</v-icon>
						</div>
					</template>
					<span class="fs-regular fw-600">{{ $t('panels.notifications.status') + ': ' + statusMessage }}</span>
				</v-tooltip>
			</div>
		</div>

		<!-- Card body -->
		<div class="cardbody d-flex flex-column justify-center px-3 pt-3">
			<notification-value-card-parameter
				:pollutantValue="parameter.value"
				:pollutantUnit="parameter.unit"
				:pollutantId="parameter.id"
				:color="color"
			></notification-value-card-parameter>
		</div>

		<!-- Card footer -->
		<!--<div class="cardbody d-flex flex-column justify-center px-3 pt-3">
			<notification-value-card-footer></notification-value-card-footer>
		</div>-->
	</v-card>
</template>

<script>
import NotificationValueCardStatus from './NotificationValueCardStatus';
import NotificationValueCardParameter from './NotificationValueCardParameter';
//import NotificationValueCardFooter from './NotificationValueCardFooter';
import { getStatusProperties } from '@/api/common';
export default {
	name: 'NotificationValueCard',

	data() {
		return {
			visible: false,
			activeStatus: null,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},

	props: {
		parameter: {
			type: Object,
			required: true
		}
	},

	components: {
		NotificationValueCardStatus,
		NotificationValueCardParameter
		//NotificationValueCardFooter,
	},

	computed: {
		notificationId() {
			return 'notificationId';
		},

		buttonTitle() {
			return this.$t('panels.notifications.goDetailNotification');
		},
		color() {
			return this.activeStatus ? this.activeStatus.color : 'var(--gris-300)';
		},
		statusMessage() {
			return this.activeStatus ? this.activeStatus.name : null;
		}
	},
	mounted() {
		let parameterStatus = this.$store.getters.getParameterStatus.find((status) => status.pmparameterid == this.parameter.id);
		if (parameterStatus) {
			this.activeStatus = getStatusProperties(parameterStatus, parseFloat(this.parameter.value));
		}
		console.log('active status', this.parameter.name, this.activeStatus);
	},

	methods: {}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../../styles/eiffel-variables.pcss';
.notificationvaluecard {
	border: 1px solid var(--light-blue-grey) !important;
	border-radius: 4px;
	background-color: var(--negative) !important;
	& .cardheader {
		border-bottom: 1px solid var(--light-blue-grey);
		& .cardheadertitle {
			font-size: 1.1rem;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: var(--high);
			text-transform: capitalize !important;
			word-break: break-word;
		}
	}
	& .cardbody {
		border-bottom: 1px solid var(--light-blue-grey);
		min-height: 29px;
	}
}
</style>
