<template>
	<div class="px-3" :class="{ 'pt-3': !isAverage }">
		<div v-if="isAverage">
			<detail-list :items="items" />
		</div>
		<div v-for="(parameter, index) in parameters" :key="index">
			<notification-value-card :parameter="parameter"></notification-value-card>
		</div>
	</div>
</template>

<script>
import NotificationValueCard from './values/NotificationValueCard';
import DetailList from '../DetailList';
export default {
	name: 'NotificationTabValues',

	components: {
		NotificationValueCard,
		DetailList
	},

	props: {
		parameters: {
			type: Array,
			required: true
		},
		isAverage: {
			type: Boolean,
			required: true
		}
	},

	computed: {
		items() {
			if (this.isAverage) {
				const props = ['interval', 'population'];

				const titles = {
					interval: this.$t('panels.notifications.interval'),
					population: this.$t('panels.notifications.population')
				};

				const visible = (value) => value !== null && value !== undefined;

				return props.map((prop) => ({ title: titles[prop], value: this[prop], visible: visible(this[prop]) }));
			}
			return [];
		},
		interval() {
			if (this.isAverage && this.parameters[0]['isaverage'] == 1) {
				return this.parameters[0].interval;
			}
			return null;
		},
		population() {
			if (this.isAverage && this.parameters[0]['isaverage'] == 1) {
				return this.parameters[0].population;
			}
			return null;
		}
	},
	mounted() {
		console.log('dev help', this.parameters, this.isAverage);
	}
};
</script>
