<template>
	<div style="display: flex; align-items: center; gap: 0.6rem">
		<!-- <div class="line-chart__percentage">{{ value }}<span class="line-chart__symbol">%</span></div> -->

		<v-progress-linear
			:value="value"
			:color="color"
			rounded
			background-color="rgba(180, 180, 180, .3)"
			style="align-self: flex-end; margin-bottom: 0.5em"
		/>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: Number,
			required: true
		},

		color: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="postcss">
.line-chart {
	&__percentage {
		font-size: 1.25rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.11;
		letter-spacing: normal;
		color: #707276;
		white-space: nowrap;
		margin-right: 15px;
	}

	&__symbol {
		font-size: 2.25rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: italic;
		line-height: 1.25;
		letter-spacing: normal;
		color: #707276;
	}
}
</style>
